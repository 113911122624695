import { FC, PropsWithChildren, useState } from "react";
import { Page } from "types";
import { NavBar } from "./NavBar";
import cn from "classnames";

export interface HeaderProps {
  pages: Array<Page>;
}

export const Header: FC<HeaderProps> = (
  props: PropsWithChildren<HeaderProps>,
): JSX.Element => {
  const { children, pages } = props;

  const [showDropdown, setShowDropdown] = useState(false);

  return (
    <div
      className={cn(
        "bg-gradient-to-r from-blue-400 via-purple-500 to-pink-500 m-2 rounded-xl flex items-center justify-center flex-col transition-all ease-in-out shadow-sm shadow-gray-200",
        !showDropdown
          ? "h-36 sm:h-52 md:h-68 lg:h-64"
          : "h-52 pb-24 sm:h-52 sm:pb-16 md:h-72 md:pb-16 lg:h-72 lg:pb-0",
      )}
    >
      <NavBar
        pages={pages}
        showDropdown={showDropdown}
        setShowDropdown={setShowDropdown}
      />
      {children}
    </div>
  );
};
