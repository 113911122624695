import classNames from "classnames";
import { Dropdown } from "./Dropdown";
import { FC, PropsWithChildren } from "react";
import { Link } from "react-router-dom";
import { MenuIcon, XIcon } from "@heroicons/react/solid";
import { Page } from "types";
import { useLocation } from "react-router";

export interface NavBarProps {
  pages: Array<Page>;
  showDropdown: boolean;
  setShowDropdown: (showDropdown: boolean) => void;
}

export const NavBar: FC<NavBarProps> = (
  props: PropsWithChildren<NavBarProps>,
): JSX.Element => {
  const { pages, showDropdown, setShowDropdown } = props;

  const { pathname } = useLocation();

  const links = pages.map(({ path, text }: Page, index: number) => {
    return path.startsWith("/") ? (
      <Link
        key={index}
        className={classNames(
          "text-base hover:text-blue-400 bg-gray-100 hover:bg-blue-100 transition-all ease-in duration-75 rounded-xl px-5 py-2 shadow-sm shadow-gray-300 hover:shadow-blue-100",
          path === pathname ? "text-blue-400" : "text-gray-900",
        )}
        to={path}
      >
        {text}
      </Link>
    ) : (
      <a
        href={path}
        key={index}
        className="text-base hover:text-blue-400 bg-gray-100 hover:bg-blue-100 transition-all ease-in duration-75 rounded-xl px-5 py-2 shadow-sm shadow-gray-300 hover:shadow-blue-100"
      >
        {text}
      </a>
    );
  });

  const linkView = (
    <div className="invisible xl:visible xl:flex h-full w-full justify-center items-center space-x-8 xl:pl-20">
      {links}
    </div>
  );

  const icon = (
    <button
      onClick={() => setShowDropdown(!showDropdown)}
      className="absolute right-2 md:right-4 align-middle visible xl:invisible xl:hidden"
    >
      <div className="flex items-center text-gray-400 hover:text-gray-600">
        <span className="sr-only">Page Navigation</span>
        {showDropdown ? (
          <XIcon className="h-5 w-5" aria-hidden="true" />
        ) : (
          <MenuIcon className="h-5 w-5" aria-hidden="true" />
        )}
      </div>
    </button>
  );

  return (
    <>
      <div
        className={classNames(
          "h-1/4 min-h-min w-8/12 bg-white shadow flex flex-col items-center justify-center relative",
          !showDropdown ? "rounded-xl" : "rounded-t-xl mb-1",
        )}
      >
        <h2 className="text-xs sm:text-sm md:text-base text-blue-400 font-semibold absolute left-4 md:left-6 align-middle uppercase">
          <Link to={"/"}>Abe Hoffman</Link>
        </h2>
        {linkView}
        {icon}
        <Dropdown pages={pages} show={showDropdown} />
      </div>
    </>
  );
};
