import { FC, PropsWithChildren } from "react";
import { Header, Footer, StreamHero } from "components";
import { Page } from "types";

export const MainLayout: FC = (props: PropsWithChildren<{}>): JSX.Element => {
  const { children } = props;

  const pages: Array<Page> = [
    {
      path: "/",
      text: "home",
    },
    {
      path: "/about",
      text: "about",
    },
    {
      path: "https://changelog.abehoff.com",
      text: "changelog",
    },
    {
      path: "/resume",
      text: "resume",
    },
  ];

  return (
    <>
      <div className="min-h-screen bg-white">
        <Header pages={pages} />
        {children}
        <Footer pages={pages}></Footer>
      </div>
    </>
  );
};
