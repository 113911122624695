import { FC } from "react";
import { Router } from "routes";
// import { reducer, ThoughtStateProvider } from "state";

export const App: FC = (): JSX.Element => {
  // const [thoughtState, dispatch] = useReducer(reducer, { thoughts: [] });

  /**
   *
   *  <ThoughtStateProvider
   *  value={{ thoughtState: thoughtState, dispatch: dispatch }}
   *  >
   *    <Router />
   *  </ThoughtStateProvider>
   */

  return <Router />;
};

export default App;
