import axios from "axios";
import { Error } from "types";

/**
 * Instantiate the axios client.
 */
const client = axios.create({
  baseURL: "https://0ph7cjhtwe.execute-api.us-east-1.amazonaws.com/prod",
  headers: {
    "Content-Type": "application/json",
  },
});

/**
 * The request params.
 */
export interface Request {
  /**
   * The API path.
   */
  path: string;

  /**
   * The request data.
   */
  data: {
    [key: string]: string | number | boolean;
  };
}

/**
 * A request helper function to post to a specified path.
 * @param request: Request
 */
export const request = async <T>(
  request: Request,
): Promise<{ data?: T; error?: Error }> => {
  const { path, data: requestData } = request;

  try {
    const response = await client.post(path, requestData);

    return { data: response.data };
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const message = error.response.data?.message || "An error occured.";

      return { error: { message: message } };
    } else {
      console.error(error);
      return { error: { message: "Error." } };
    }
  }
};
