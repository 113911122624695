import { FC } from "react";

import { MainLayout } from "layouts";

export const NotFound: FC = (): JSX.Element => {
  return (
    <MainLayout>
      <div className="w-screen h-screen flex items-center justify-center bg-gray-100">
        <div className="text-xs">oops, something big is coming here 🛠</div>
      </div>
    </MainLayout>
  );
};
