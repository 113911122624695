import { createContext, Dispatch } from "react";
import { ThoughtState, ThoughtAction } from "state";

export interface ThoughtStateContextProps {
  /**
   * The current thought state.
   */
  readonly thoughtState: ThoughtState;
  /**
   * The dispatch function to update the thought state.
   */
  readonly dispatch: Dispatch<ThoughtAction>;
}

/**
 * A default no operation method.
 */
const NoOp = (): void => {
  /**
   * DO NOTHING
   */
};

const initialContext: ThoughtStateContextProps = {
  thoughtState: { thoughts: [] },
  dispatch: NoOp,
};

export const ThoughtStateContext =
  createContext<ThoughtStateContextProps>(initialContext);

export const ThoughtStateProvider = ThoughtStateContext.Provider;
