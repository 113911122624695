import { FC, Fragment } from "react";
import { Transition } from "@headlessui/react";
import { Link } from "react-router-dom";
import { Page } from "types";

import { useLocation } from "react-router";
import classNames from "classnames";

export interface DropdownProps {
  pages: Array<Page>;
  show: boolean;
}

export const Dropdown: FC<DropdownProps> = (
  props: DropdownProps,
): JSX.Element => {
  const { pages, show } = props;

  const { pathname } = useLocation();
  console.log(pathname);

  const dropdownItems = pages.map(({ path, text }: Page, index: number) => {
    return path.startsWith("/") ? (
      <Link
        key={index}
        className={classNames(
          "text-base hover:text-blue-400 w-full",
          path === pathname ? "text-blue-400" : "text-gray-900",
        )}
        to={path}
      >
        <div className="hover:bg-blue-100 hover:rounded-md w-full px-2 md:px-4">
          {text}
        </div>
      </Link>
    ) : (
      <a
        href={path}
        key={index}
        className={"text-base hover:text-blue-400 w-full"}
      >
        <div className="hover:bg-blue-100 hover:rounded-md w-full px-2 md:px-4">
          {text}
        </div>
      </a>
    );
  });

  return (
    <div className="w-full mt-24 xl:hidden">
      <Transition
        as={Fragment}
        show={show}
        enter="transition ease-in-out duration-200"
        enterFrom="transform opacity-0 -translate-y-10 scale-95"
        enterTo="transform opacity-100 translate-y-0 scale-100"
        leave="transition ease-in duration-100"
        leaveFrom="transform opacity-75 translate-y-0 scale-100"
        leaveTo="transform opacity-0 -translate-y-10 scale-95"
      >
        <div className="py-1 px-2 flex flex-col divide-y bg-white rounded-b-xl">
          {dropdownItems}
        </div>
      </Transition>
    </div>
  );
};
