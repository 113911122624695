import cn from "classnames";
import { FC, PropsWithChildren } from "react";
import { formatDate } from "./formatDate";
import { LinkIcon } from "@heroicons/react/solid";
import { Skeleton, CopyButton } from "components";
import { useLocation, useHref } from "react-router";

export interface StreamProps {
  id?: string;
  loading?: boolean;
  timestamp?: number;
  message?: string;
}

export const Stream: FC<StreamProps> = (
  props: PropsWithChildren<StreamProps>,
): JSX.Element => {
  const {
    id = "1",
    loading = false,
    timestamp = 1640738836,
    message = "Hello world!",
  } = props;

  const { hash } = useLocation();

  const href = `#${id}`;
  const link = `${window.location.origin}${useHref(href)}`;

  const copyToClipboard = () => {
    navigator.clipboard.writeText(link);
  };

  if (loading) {
    return (
      <div className="w-11/12 sm:w-1/2 md:1/3 h-40 bg-white rounded-xl shadow-md hover:shadow-xl grid grid-cols-1 justify-content-end">
        <div className="flex justify-between mx-4 mt-2 animate-pulse">
          <div className="text-gray-600 font-bold text-gray-400 opacity-50">
            #
          </div>
          <LinkIcon className="h-5 w-5 text-gray-400 opacity-50" />
        </div>
        <div className="mx-4 mb-2">
          <Skeleton size="medium" />
        </div>
      </div>
    );
  }

  const date = formatDate(timestamp);

  return (
    <div
      id={id}
      className={cn(
        "w-11/12 sm:w-1/2 md:1/3 h-40 bg-white rounded-xl shadow-md hover:shadow-xl grid grid-cols-1 justify-content-end",
        hash === href &&
          "border-2 border-blue-200 shadow-blue-200 hover:shadow-blue-200",
      )}
    >
      <div className="flex justify-between mx-4 mt-2">
        <a className="text-gray-600 font-bold" href={href}>
          {href}
        </a>
        <CopyButton onClick={() => copyToClipboard()}>
          <LinkIcon className="h-5 w-5 text-gray-400 hover:text-blue-400" />
        </CopyButton>
      </div>
      <div className="flex items-center justify-center mx-4 text-md sm:text-lg">
        {message}
      </div>
      <div className="text-xs lg:text-sm text-gray-400 flex items-end mx-4 mb-2">
        {date}
      </div>
    </div>
  );
};
