import { FC } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { NotFound, Gateway } from "pages";
import { ScrollToTop } from "components";

export const Router: FC = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Gateway />} />

        {/* The 404 Not Found must ALWAYS Be the last route. */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};
