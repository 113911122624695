import { FC, PropsWithChildren, SVGProps } from "react";

import { Link } from "react-router-dom";

import { Page } from "types";

export type Social = {
  name: string;
  link: string;
  icon: SVGProps<SVGElement>;
};

export interface FooterProps {
  pages: Array<Page>;
  socials?: Array<Social>;
}

export const Footer: FC<FooterProps> = (
  props: PropsWithChildren<FooterProps>,
): JSX.Element => {
  const { pages, socials } = props;

  const links = pages.map(({ path, text }: Page, index: number) => {
    return (
      <Link
        key={index}
        className="text-base text-blue-400 hover:text-gray-500 px-5 py-2"
        to={path}
      >
        {text}
      </Link>
    );
  });

  return (
    <footer className="bg-gray-100 flex flex-col items-center mt-4 rounded-xl shadow shadow-gray-300 m-2">
      <div className="border-t border-gray-400" />
      <div className="max-w-7xl mx-auto py-6 px-4 overflow-hidden sm:px-6 lg:px-8">
        <div className="-mx-5 -my-4 flex justify-center">{links}</div>
        <div className="mt-4 flex justify-center space-x-6">
          {socials?.map(({ name, link, icon }: Social, index: number) => (
            <a
              key={index}
              href={link}
              className="text-blue-400 hover:text-gray-400"
            >
              <span className="sr-only">{name}</span>
              {icon}
            </a>
          ))}
        </div>
        <p className="mt-4 text-center text-base text-blue-400">
          &copy; 2022 abe hoffman
        </p>
      </div>
    </footer>
  );
};
