import { Thought } from "types";

export interface ThoughtState {
  thoughts: Array<Thought>;
}

export interface ThoughtAction {
  type: string;
  payload: Array<Thought>;
}

export enum ActionType {
  LIST = "LIST",
}

export const reducer = (
  state: ThoughtState,
  action: ThoughtAction,
): ThoughtState => {
  switch (action.type) {
    case ActionType.LIST:
      return { ...state, thoughts: action.payload };

    default:
      return state;
  }
};
